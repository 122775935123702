import { createContext, useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useSocket } from 'contexts/SocketContext';
import { useCallback } from 'react';
import Modals from './Modals';
import Platform from 'constants/platform';
import useYoutubeVerifier from './useYoutubeVerifier';
import useTwitterVerifier from './useTwitterVerifier';
import useTikTokVerifier from './useTikTokVerifier';
import useInstagramVerifier from './useInstagramVerifier';
import UpdateInstaConnectionModal from './Modals/InstaModals/UpdateInstaConnectionModal';

const SocialVerifierContext = createContext({
  verify: ({ platform, reqType, isBasic }) => {},
  loading: false,
  updateInstagramConnection: ({ reqType }) => {},
});

const SocialVerifier = ({
  setLinks,
  onPopupFail,
  errorModalPrimaryButtonClick,
  onErrorClose,
  children,
}) => {
  const socket = useSocket();

  const [error, setError] = useState({
    code: null,
    message: null,
    platform: null,
  });
  const [loading, setLoading] = useState({});
  const [isCaller, setIsCaller] = useState(true);

  useEffect(() => {
    if (!isCaller) return;

    const startAddLink = (p) => {
      setLoading((prev) => ({
        ...prev,
        [p.platform]: true,
      }));
    };

    socket.on('startAddLink', startAddLink);

    return () => {
      socket.off('startAddLink', startAddLink);
    };
  }, [isCaller, socket]);

  useEffect(() => {
    const addLink = async (res) => {
      if (
        (res.status?.toString()?.startsWith('4') || res.status === 500) &&
        isCaller
      ) {
        setError({
          code: res.status,
          message: res.message,
        });
        setLoading((prev) => ({
          ...prev,
          [res.data.platform]: false,
        }));
      }

      if (res.status === 200) {
        setLinks(res.message);
        setLoading((prev) => ({
          ...prev,
          // ...(res.message.map((m) => ({ [m.type]: false })) || {}),
          ...(res?.message?.reduce((acc, m) => {
            acc[m.type] = false;
            return acc;
          }, {}) || {}),
        }));
      }

      setIsCaller(false);
    };

    socket.on('addLink', addLink);

    return () => {
      socket.off('addLink', addLink);
    };
  }, [isCaller, setLinks, socket]);

  // listen to remove link socket event
  useEffect(() => {
    const removeLink = (res) => {
      setLinks(res.message);
    };
    socket.on('removeLink', removeLink);

    return () => {
      socket.off('removeLink', removeLink);
    };
  }, [setLinks, socket]);

  const verifyYoutube = useYoutubeVerifier({
    onError: ({ code, message }) =>
      setError({ code, message, platform: Platform.YouTube }),
    onPopupFail,
  });
  const verifyTwitter = useTwitterVerifier({
    onPopupFail,
  });
  const verifyTikTok = useTikTokVerifier({
    onPopupFail,
  });
  const verifyInstagram = useInstagramVerifier({
    onPopupFail,
  });

  const verify = useCallback(
    ({ platform, reqType = 'permanent', isBasic }) => {
      setIsCaller(true);

      switch (platform) {
        case Platform.YouTube:
          verifyYoutube(reqType);
          break;
        case Platform.Twitter:
          verifyTwitter(reqType);
          break;
        case Platform.TikTok:
          verifyTikTok(reqType);
          break;
        case Platform.Instagram:
          verifyInstagram(reqType, isBasic);
          break;
        default:
          break;
      }
    },
    [verifyYoutube, verifyTwitter, verifyTikTok, verifyInstagram]
  );

  const [updateInstaConnection, setUpdateInstaConnection] = useState(false);
  const [instagramReqType, setInstagramReqType] = useState('permanent');

  const updateInstagramConnection = useCallback(({ reqType = 'permanent' }) => {
    setInstagramReqType(reqType);
    setUpdateInstaConnection(true);
  }, []);

  const value = useMemo(() => {
    return {
      verify,
      loading,
      updateInstagramConnection,
    };
  }, [verify, loading, updateInstagramConnection]);

  return (
    <SocialVerifierContext.Provider value={value}>
      {children}

      <Modals
        error={error}
        onClose={() => {
          setError({
            code: null,
            message: null,
          });
          onErrorClose?.();
        }}
        onPrimaryButtonClick={errorModalPrimaryButtonClick}
      />

      <UpdateInstaConnectionModal
        isOpen={updateInstaConnection}
        onClose={() => setUpdateInstaConnection(false)}
        buttonOnClick={() => {
          setUpdateInstaConnection(false);
        }}
        onVerify={setLinks}
        reqType={instagramReqType}
        onPopupFail={onPopupFail}
      />
    </SocialVerifierContext.Provider>
  );
};

export default SocialVerifier;

export const useSocialVerifier = (platform) => {
  const context = useContext(SocialVerifierContext);

  if (!context) {
    throw new Error(
      'useSocialVerifier must be used within a SocialVerifierProvider'
    );
  }

  return {
    verify: ({ reqType, isBasic }) =>
      context.verify({ platform, reqType, isBasic }),
    loading: context.loading[platform],
    updateInstagramConnection: context.updateInstagramConnection,
  };
};
