import styled from 'styled-components';
import Heading from 'components/Basics/Typography/Heading';
import { useFormContext } from 'react-hook-form';
import Content from './components/Content';
import Stack from 'components/Basics/Layout/Stack';
import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import { useToggle } from 'react-use';
import CompanyDescription from './components/CompanyDescription';
import sendVerificationEmail from '../api/sendVerificationEmail';
import { LoadingIcon, UploadIcon } from 'assets/icons';
import resendVerificationCode from '../api/resendVerificationCode';
import submitVerification from '../api/submitVerification';
import FileUploader from './components/FileUploader';
import { useState } from 'react';
import verifiyClientEmail from '../api/verifiyClientEmail';
import { useToast } from 'components/Basics/Toast';
import useUser from 'hooks/useUser';
import clientPlans from 'constants/clientPlans';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useOfficialLaunchModal } from 'contexts/OfficialLaunchModal';
import { Trans } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];

const FinalStep = ({ plan, onClose, setIsFormSubmittedPopupVisible }) => {
  const { t } = useTranslation();
  const { watch, formState, setValue, setFocus, setError } = useFormContext();
  const [canChangeEmail, setCanChangeEmail] = useToggle(true);
  const [haveNoComapnyEmail, setHaveNoComapnyEmail] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isResendLoading, setIsResendLoading] = useToggle(false);
  const { getValues, trigger } = useFormContext();
  const [clientEmailVerificationId, setClientEmailVerificationId] =
    useState('');
  const [firstVerifyEmail, setFirstVerifyEmail] = useToggle(false);
  const toast = useToast();
  const { isMobile } = useResponsiveScreen();

  const { refetchUser, user } = useUser();
  const navigate = useNavigate();

  const submit = async () => {
    try {
      if (haveNoComapnyEmail) {
        const isValid = await trigger([
          'companyDescription',
          'verificationFile',
        ]);
        if (isValid) {
          const {
            firstName,
            lastName,
            companyName,
            companyType,
            companyWebsite,
            jobPosition,
            country,
            companyDescription,
            verificationFile,
          } = getValues();
          setIsLoading(true);

          submitVerification({
            firstName,
            lastName,
            companyName,
            companyType,
            companyWebsite,
            jobPosition,
            country,
            companyDescription,
            verificationFile: verificationFile[0]?.obj,
          })
            .then((res) => {
              onClose();
              refetchUser();
              setIsFormSubmittedPopupVisible(true);
            })
            .catch((res, err) => {
              toast.error(t(ProfileKeys.Menu.Error.something_went_wrong));
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else {
        const {
          firstName,
          lastName,
          companyName,
          companyType,
          companyWebsite,
          jobPosition,
          country,
          companyEmail,
        } = getValues();
        setIsLoading(true);

        submitVerification({
          clientEmailVerificationId: clientEmailVerificationId,
          firstName,
          lastName,
          companyName,
          companyType,
          companyWebsite,
          jobPosition,
          country,
          companyEmail,
        })
          .then((res) => {
            onClose();
            if (user?.clientPlan?.status === 'unverified') {
              setIsFormSubmittedPopupVisible(true);
            } else {
              toast.success(t(PricingKeys.Modal.toast));
              // uncomment this to enable /checkout
              // if (plan === clientPlans.Premium) {
              //   navigate('/checkout', {
              //     state: {
              //       type: 'subscription',
              //       subscriptionPlan: 'premuim',
              //       price: 99,
              //     },
              //   });
              // }
            }
            refetchUser();
          })
          .catch((res, err) => {
            toast.error(t(ProfileKeys.Menu.Error.something_went_wrong));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!haveNoComapnyEmail ? (
        <>
          <Content
            name="companyEmail"
            placeholder={t(PricingKeys.Modal.Placeholder.companyEmail)}
            disabled={!canChangeEmail}
            focus={canChangeEmail}
            renderOnRight={
              canChangeEmail ? null : (
                <TextButton
                  onClick={() => {
                    setCanChangeEmail(true);
                    setFocus('companyEmail');
                    setError('companyEmail', null);
                  }}
                  disabled={isLoading}
                  className="h6"
                >
                  {t(PricingKeys.Modal.Btn.another_email)}
                </TextButton>
              )
            }
          />
          {canChangeEmail && (
            <span
              className={`w-100 h6.5 line-height-1.5 ${
                isMobile ? 'text-start' : 'text-end'
              }`}
            >
              *{' '}
              <TextButton className="h6.5" onClick={setHaveNoComapnyEmail}>
                {t(PricingKeys.Modal.Btn.no_company_email)}
              </TextButton>
            </span>
          )}
          {watch('companyEmail') &&
            !formState.errors.companyEmail?.message &&
            !canChangeEmail && (
              <>
                <Content
                  name="code"
                  placeholder={t(PricingKeys.Modal.Placeholder.enter_code)}
                  type="number"
                  renderOnRight={
                    <TextButton
                      onClick={async () => {
                        setIsResendLoading(true);
                        resendVerificationCode({
                          clientEmailVerificationId,
                        })
                          .then((res) => {
                            setIsResendLoading(false);
                          })
                          .catch(() => {
                            toast.error(
                              t(ProfileKeys.Menu.Error.something_went_wrong)
                            );
                          });
                      }}
                      disabled={isResendLoading}
                      className="d-flex gap-2 align-items-center"
                    >
                      {isResendLoading && <LoadingIcon size={0.65} />}
                      {t(PricingKeys.Modal.Btn.resend_code)}
                    </TextButton>
                  }
                  maxLength="6"
                />
                <Heading variant="H7" color="var(--color-indigo-100)">
                  {t(PricingKeys.Modal.Final.spam_explain)}
                </Heading>
              </>
            )}
          <Stack
            padding={isMobile ? '' : '180px 0 0 0'}
            className={`align-self-center ${isMobile ? 'mt-auto' : ''}`}
            width={isMobile ? '100%' : ''}
          >
            {canChangeEmail && (
              <IndigoRoundButton
                onClick={async () => {
                  setIsLoading(true);
                  sendVerificationEmail(watch('companyEmail'))
                    .then((res) => {
                      setClientEmailVerificationId(
                        res?.clientEmailVerificationId
                      );
                      setCanChangeEmail(false);
                      setValue('code', '');
                      setFirstVerifyEmail(true);
                    })
                    .catch((res, err) => {
                      if (res.key === 'EMAIL_ALREADY_VERIFIED') {
                        setError('companyEmail', {
                          type: 'custom',
                          message: t(PricingKeys.Modal.Error.already_verified),
                        });
                      } else if (res.key === 'NOT_VALID_EMAIL') {
                        setError('companyEmail', {
                          type: 'custom',
                          message: t(PricingKeys.Modal.Error.valid_email),
                        });
                      } else if (res.key === 'RESTRICTED_EMAIL_DOMAIN') {
                        setError('companyEmail', {
                          type: 'custom',
                          message: t(PricingKeys.Modal.Error.email_validation),
                        });
                      } else {
                        toast.error(
                          t(ProfileKeys.Menu.Error.something_went_wrong)
                        );
                      }
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
                disabled={
                  !watch('companyEmail') ||
                  formState.errors.companyEmail?.message ||
                  isLoading
                }
                className={isMobile ? 'rounded-2' : ''}
              >
                <Stack direction="row" align="center">
                  {isLoading && <LoadingIcon size={0.85} />}
                  {t(PricingKeys.Modal.Btn.verify_email)}
                </Stack>
              </IndigoRoundButton>
            )}
            {watch('companyEmail') &&
              !formState.errors.companyEmail?.message &&
              !canChangeEmail &&
              firstVerifyEmail && (
                <IndigoRoundButton
                  onClick={() => {
                    verifiyClientEmail({
                      clientEmailVerificationId: clientEmailVerificationId,
                      token: watch('code'),
                    })
                      .then((res) => {
                        if (
                          res.message === 'Successfully verified company email'
                        ) {
                          submit();
                        }
                      })
                      .catch((res, err) => {
                        if (res.key === 'TOKEN_NOT_MATCH') {
                          setError('code', {
                            type: 'custom',
                            message: t(PricingKeys.Modal.Error.invalid_code),
                          });
                        } else {
                          toast.error(
                            t(ProfileKeys.Menu.Error.something_went_wrong)
                          );
                        }
                      });
                  }}
                  disabled={
                    !watch('code') ||
                    formState.errors.code?.message ||
                    isLoading
                  }
                >
                  <Stack direction="row" align="center">
                    {isLoading && <LoadingIcon size={0.85} />}
                    {t(PricingKeys.Modal.Btn.complete_verification)}
                  </Stack>
                </IndigoRoundButton>
              )}
          </Stack>
        </>
      ) : (
        <>
          <Stack gap="1rem">
            <ul
              className={
                isMobile
                  ? 'h7 d-flex flex-column gap-3'
                  : 'h6.5 line-height-1.5'
              }
            >
              <Trans components={{ li: <li></li> }}>
                {t(PricingKeys.Modal.Final.explain)}
              </Trans>
            </ul>

            <FileUploader
              name="verificationFile"
              minRows={1}
              maxFiles={1}
              accept={ALLOWED_FILE_TYPES}
              maxSize={5 * 1024 * 1024}
              placeholder={(open) => (
                <Stack gap="1rem" align="center">
                  <Stack
                    align="center"
                    className={`${isMobile ? 'sbh7' : 'sbh6'} text-indigo-100`}
                  >
                    <UploadIcon size={isMobile ? 0.7 : 1} />
                    <span>{t(PricingKeys.Modal.Final.image_placeholder)}</span>
                  </Stack>
                  <IndigoRoundButton size="xs" onClick={open}>
                    {t(GeneralKeys.browse_files)}
                  </IndigoRoundButton>
                </Stack>
              )}
            />
          </Stack>
          <CompanyDescription />
          <Stack
            direction="row"
            align="center"
            gap="2.25rem"
            justify="center"
            width="100%"
            className={isMobile ? 'mt-auto' : ''}
          >
            <IndigoRoundButton onClick={submit} disabled={isLoading}>
              {isLoading && <LoadingIcon size={0.85} />}
              {t(GeneralKeys.submit)}
            </IndigoRoundButton>
            <TextButton
              className="sbh5 text-decoration-none"
              onClick={setHaveNoComapnyEmail}
              disabled={isLoading}
            >
              {t(PricingKeys.Modal.Btn.verify_with_email)}
            </TextButton>
          </Stack>
        </>
      )}
    </>
  );
};

export default FinalStep;

const TextButton = styled.button.attrs({
  className: 'h6',
})`
  text-decoration-line: underline;
  color: var(--color-indigo-500);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;

    &:hover {
      opacity: 0.7;
    }
  }
`;
