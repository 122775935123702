import styled from 'styled-components';
import { TimeIcon } from 'assets/icons';
import Stack from 'components/Basics/Layout/Stack';
import Modal from 'components/Basics/Modal';
import ModalContent from './ModalContent';
import { useTranslation } from 'react-i18next';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const ClientVerificationModal = ({
  isOpen,
  onClose,
  setIsFormSubmittedPopupVisible,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();

  return (
    <Modal
      title={
        <Stack
          direction="row"
          gap={isMobile ? '0.75rem' : '1.5rem'}
          align="center"
        >
          {t(PricingKeys.Modal.title)}
          <Estimate className="h7">
            <TimeIcon />
            {t(PricingKeys.Modal.estimate)}
          </Estimate>
        </Stack>
      }
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      backdropClosesModal={false}
      fullScreen={isMobile}
      backButton={false}
      displayCloseButton={true}
    >
      <ModalContent
        onClose={onClose}
        setIsFormSubmittedPopupVisible={setIsFormSubmittedPopupVisible}
        plan={isOpen}
      />
    </Modal>
  );
};

export default ClientVerificationModal;

const Estimate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-indigo-100);
  flex-wrap: nowrap;
`;
