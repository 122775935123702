const PricingKeys = {
  preparing: 'PricingKeys.preparing',
  order_now: 'PricingKeys.order_now',
  get_free: 'PricingKeys.get_free',
  get_for_free: 'PricingKeys.get_for_free',
  current_plan: 'PricingKeys.current_plan',
  company_verified: 'PricingKeys.company_verified',

  Banner: {
    title: 'PricingKeys.Banner.title',
    description: 'PricingKeys.Banner.description',
  },

  Verified: {
    Error: {
      already_verified: 'PricingKeys.Verified.Error.already_verified',
      already_using: 'PricingKeys.Verified.Error.already_using',
      under_review: 'PricingKeys.Verified.Error.under_review',
    },
  },

  Left_Col: {
    General: {
      verification_req: 'PricingKeys.Left_Col.General.verification_req',
      smart_search: 'PricingKeys.Left_Col.General.smart_search',
    },

    Analytics_Data: {
      title: 'PricingKeys.Left_Col.Analytics_Data.title',

      Creator_Basic_Analytics: {
        title:
          'PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title',
        tooltip:
          'PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip',
      },

      audience_analytics:
        'PricingKeys.Left_Col.Analytics_Data.audience_analytics',

      Price_Unlock: {
        title: 'PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title',
        tooltip: 'PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip',
      },

      order_analytics: 'PricingKeys.Left_Col.Analytics_Data.order_analytics',

      creator_reviews: 'PricingKeys.Left_Col.Analytics_Data.creator_reviews',
    },

    Orders: {
      title: 'PricingKeys.Left_Col.Orders.title',
      order_packages: 'PricingKeys.Left_Col.Orders.order_packages',
      message_creators: 'PricingKeys.Left_Col.Orders.message_creators',

      Order_Discount: {
        title: 'PricingKeys.Left_Col.Orders.Order_Discount.title',
        tooltip_1: 'PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1',
        tooltip_2: 'PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2',
        content: 'PricingKeys.Left_Col.Orders.Order_Discount.content',
      },

      Track_Delivery: {
        title: 'PricingKeys.Left_Col.Orders.Track_Delivery.title',
        tooltip: 'PricingKeys.Left_Col.Orders.Track_Delivery.tooltip',
      },

      auto_translation: 'PricingKeys.Left_Col.Orders.auto_translation',
    },
  },

  Summary: {
    basic: 'PricingKeys.Summary.basic',
    verified: 'PricingKeys.Summary.verified',
    premium: 'PricingKeys.Summary.premium',
  },

  Creator: {
    title: 'PricingKeys.Creator.title',

    h1: 'PricingKeys.Creator.h1',
    h2: 'PricingKeys.Creator.h2',
    h3: 'PricingKeys.Creator.h3',
  },

  Modal: {
    title: 'PricingKeys.Modal.title',
    estimate: 'PricingKeys.Modal.estimate',
    caution: 'PricingKeys.Modal.caution',
    toast: 'PricingKeys.Modal.toast',

    Btn: {
      proceed_final: 'PricingKeys.Modal.Btn.proceed_final',
      verify_email: 'PricingKeys.Modal.Btn.verify_email',
      no_company_email: 'PricingKeys.Modal.Btn.no_company_email',
      verify_with_email: 'PricingKeys.Modal.Btn.verify_with_email',
      complete_verification: 'PricingKeys.Modal.Btn.complete_verification',
      another_email: 'PricingKeys.Modal.Btn.another_email',
      resend_code: 'PricingKeys.Modal.Btn.resend_code',
    },

    Placeholder: {
      firstname: 'PricingKeys.Modal.Placeholder.firstname',
      lastname: 'PricingKeys.Modal.Placeholder.lastname',
      companyname: 'PricingKeys.Modal.Placeholder.companyname',
      companyUrl: 'PricingKeys.Modal.Placeholder.companyUrl',
      companyUrl_sm: 'PricingKeys.Modal.Placeholder.companyUrl_sm',
      companyEmail: 'PricingKeys.Modal.Placeholder.companyEmail',
      enter_code: 'PricingKeys.Modal.Placeholder.enter_code',
    },

    CompanyType: {
      title: 'PricingKeys.Modal.CompanyType.title',
      option_1: 'PricingKeys.Modal.CompanyType.option_1',
      option_2: 'PricingKeys.Modal.CompanyType.option_2',
      option_3: 'PricingKeys.Modal.CompanyType.option_3',
      option_4: 'PricingKeys.Modal.CompanyType.option_4',
    },

    JobPosition: {
      title: 'PricingKeys.Modal.JobPosition.title',
      option_1: 'PricingKeys.Modal.JobPosition.option_1',
      option_2: 'PricingKeys.Modal.JobPosition.option_2',
      option_3: 'PricingKeys.Modal.JobPosition.option_3',
      option_4: 'PricingKeys.Modal.JobPosition.option_4',
      option_5: 'PricingKeys.Modal.JobPosition.option_5',
      option_6: 'PricingKeys.Modal.JobPosition.option_6',
      option_7: 'PricingKeys.Modal.JobPosition.option_7',
      option_8: 'PricingKeys.Modal.JobPosition.option_8',
      option_9: 'PricingKeys.Modal.JobPosition.option_9',
    },

    Final: {
      explain: 'PricingKeys.Modal.Final.explain',
      image_placeholder: 'PricingKeys.Modal.Final.image_placeholder',
      description_placeholder:
        'PricingKeys.Modal.Final.description_placeholder',
      spam_explain: 'PricingKeys.Modal.Final.spam_explain',
    },

    Popup: {
      title: 'PricingKeys.Modal.Popup.title',
      description: 'PricingKeys.Modal.Popup.description',
    },

    submission: {
      toast: 'PricingKeys.Modal.submission.toast',
    },

    Error: {
      required_field: 'PricingKeys.Modal.Error.required_field',
      select_option: 'PricingKeys.Modal.Error.select_option',
      valid_url: 'PricingKeys.Modal.Error.valid_url',
      valid_email: 'PricingKeys.Modal.Error.valid_email',
      email_validation: 'PricingKeys.Modal.Error.valid_email',
      already_verified: 'PricingKeys.Modal.Error.already_verified',
      already_sent_code: 'PricingKeys.Modal.Error.already_sent_code',
      invalid_code: 'PricingKeys.Modal.Error.invalid_code',
      invalid_file_format: 'PricingKeys.Modal.Error.invalid_file_format',
      total_file_size: 'PricingKeys.Modal.Error.total_file_size',
      send_max_files: 'PricingKeys.Modal.Error.send_max_files',
    },
  },
};

export default PricingKeys;
