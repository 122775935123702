import TermsConditionsKeys from '../translationKeys/TermsAndConditionsPageKeys';

export const TermsAndConditionsTranslations = {
  [TermsConditionsKeys.title]: '이용 약관',

  [TermsConditionsKeys.top_text]: `다음과 같은 "이용 약관"("약관")은 대한민국 서울특별시 강남구 테헤란로 82길 15, 우편번호 06178에 소재하는 Intaverse 주식회사(이하 "MegaEvolution"이라 함)와 당사의 플랫폼과 서비스를 이용하는 사용자(이하 "사용자") 사이의 모든 비즈니스 관계에 적용됩니다. 당사의 플랫폼을 사용하기 전에 이 약관을 주의 깊게 읽어주시기 바랍니다. 이 약관은 당사의 플랫폼과 서비스의 이용에 대한 사용자와 MegaEvolution 간의 법적 계약을 구성합니다. 이 약관에 따라 당사의 플랫폼과 서비스를 사용할 수 있습니다.`,

  // 1. General Terms
  [TermsConditionsKeys.General_Terms.title]: '1. 일반 약관',

  [TermsConditionsKeys.General_Terms
    ._1_1]: `1.1. 이 약관에서의 "사용자"란 서비스 이용자(이하 "서비스 이용자")와 전문 서비스 제공자(이하 "크리에이터")를 모두 포함한 개인을 의미합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_2]: `1.2. 이 약관에서의 "서비스 이용자"란 크리에이터와 상업적 또는 독립적 전문 활동의 목적으로 행동하지 않고 비즈니스 관계를 맺는 자연인을 의미합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_3]: `1.3. 이 약관에서의 "크리에이터"란 서비스 이용자와 비즈니스 관계를 맺고 상업적이거나 자영업적 전문 활동을 수행하는 자연인 또는 법인 또는 법적 자격을 가진 개인과 회사를 의미합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_4]: `1.4. 이 약관에 명시된 규정은 당사의 플랫폼에 대한 접속 및 이용을 규제하며, 여러분과 당사 간에 법적으로 구속력 있는 계약을 구성합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_5]: `1.5. 여러분이 이 약관을 준수하기로 동의하는 경우, 우리는 본 약관에 따라 여러분에게 철회 가능하고, 배타적이며 양도할 수 없는 플랫폼 이용 라이선스를 부여합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_6]: `1.6. 계정 등록을 통해 당사에 필수적이고 자발적인 정보를 제공하고 당사의 플랫폼을 사용하는 것을 포함하여, 여러분은 다음과 같이 동의하고 인정합니다:`,
  [TermsConditionsKeys.General_Terms
    ._1_6_1]: `1.6.1. 이 약관에 명시된 조항을 읽었으며 이를 준수하고 이행하기로 동의합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_6_2]: `1.6.2. 여러분은 본 약관을 준수하도록 계정을 사용하는 모든 사용자들에게 지켜달라고 요구할 것입니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_7]: `1.7. 여러분은 계정의 기밀성을 유지하는 것에 대한 책임이 있으며, 계정에서 발생하는 모든 활동에 대한 책임을 집니다. 여러분의 계정을 통해 어떤 사람이 수행하는 모든 행동은 여러분에 의해 수행된 것으로 간주되며, 여러분의 계정에 액세스하고 사용하는 모든 사람들이 그러한 권한을 가지고 있는지 확인해야 합니다. 여러분의 계정의 부정, 사기 또는 불법적인 사용으로 인해 발생하는 손실, 피해 또는 책임에 대해서 당사는 책임지지 않습니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_8]: `1.8. 당사는 언제든지 단독 재량에 따라 어떠한 통지, 설명 또는 책임 없이 여러분을 허용하지 않거나 여러분의 플랫폼 또는 계정 접근을 일시적으로 중지하거나 플랫폼에서 콘텐츠를 제거하거나 수정하는 권리를 보유합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_9]: `1.9. 당사는 언제든지 서비스의 일부, 당사의 플랫폼 또는 관련 서비스의 어떤 부분을 변경, 수정, 일시 중단 또는 중단할 권리를 보유합니다. 여러분은 상기 중 어떤 사항에 대한 접근 또는 운영이 가끔씩 중단되거나 기술적 어려움을 겪을 수 있다는 점에 동의합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_10]: `1.10. 별도의 약관 또는 문서는 주기적으로 플랫폼에 게시될 수 있으며, 여기에 명시적으로 참조되었습니다. 우리는 단독 재량에 따라 언제든지 이러한 약관을 변경하거나 수정할 권리를 보유합니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_11]: `1.11. 여러분은 업데이트된 내용을 알아두기 위해 주기적으로 이 약관을 검토하는 것이 여러분의 책임입니다. 업데이트된 이용 약관이 게시된 날짜 이후에도 여러분이 플랫폼을 계속 사용함으로써 여러분은 해당 업데이트된 이용 약관의 변경 사항을 인지하고 수락한 것으로 간주될 것입니다.`,
  [TermsConditionsKeys.General_Terms
    ._1_12]: `1.12. 플랫폼에서 제공되는 정보는 법률이나 규제에 반하는 배포나 사용이 이루어지는 어떠한 관할권이나 국가에 소재한 어떠한 개인이나 단체에게도 제공되지 않도록 의도되었습니다. 또한, 해당 관할권이나 국가에서 당사에게 등록 요건을 부과할 수 있습니다.`,

  // 2. Your relationship with Mega Evolution
  [TermsConditionsKeys.Your_Relationship.title]: '2. MegaEvolution와의 관계',

  [TermsConditionsKeys.Your_Relationship
    ._2_1]: `2.1. MegaEvolution은 서비스를 제공하지 않으며, 단지 플랫폼에서 거래의 적절한 진행을 보장하기 위한 기술적 및 조직적 인프라를 제공합니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_2]: `2.2. MegaEvolution은 사용자 간에 독점적으로 체결되는 계약에 대해 직접 계약 당사자가 되지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3]: `2.3. 사용자는 스스로 계약으로부터 발생하는 법적 요건의 준수와 클레임의 주장에 대한 책임이 있습니다. 특히, MegaEvolution은 다음을 보장하지 않습니다:`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3_1]: `2.3.1. 서비스에 관련하여 제공되는 진술과 선언의 정확성과 완전성,`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3_2]: `2.3.2. 제공되는 서비스 그 자체에 대한 보장,`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3_3]: `2.3.3. 해당 서비스 이용자/크리에이터의 행동과 성과에 대한 보장은 MegaEvolution이 제공하지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_4]: `2.4. 사용자는 플랫폼과 서비스를 이용할 때 관련 법률을 준수해야 합니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_5]: `2.5. 각 크리에이터는 자신의 제안이 법적이며 제3자의 권리를 침해하지 않는지를 보장하는 것이 자신의 책임입니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_6]: `2.6. 사용자는 MegaEvolution을 통해 얻은 주소, 연락처 및 이메일을 계약 및 계약 이전 통신 외의 다른 목적으로 사용할 수 없습니다. 특히, 해당 사용자가 사전에 명시적으로 동의하지 않은 한 이러한 데이터를 재판매하거나 광고를 보내는 데 사용하는 것은 금지됩니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_7]: `2.7. MegaEvolution은 일반적으로 사용자가 MegaEvolution에 게시한 콘텐츠를 검토하지 않으며 MegaEvolution의 의견을 대표하지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_8]: `2.8. MegaEvolution은 Creators의 개인 세부 정보를 확인하기 위해 적절한 공식 문서를 통해 데이터의 정확성을 확인할 권한이 있습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_9]: `2.9. MegaEvolution은 가입 후 크리에이터들을 검토하고 선별하지만, MegaEvolution은 크리에이터들이 제공한 자격증명에 대해 제한된 검토만 수행할 수 있습니다. 따라서, 해당 크리에이터들이 제공하는 서비스의 정확성에 대해서는 보장할 수 없습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_10]: `2.10. MegaEvolution의 제공 내용은 인터넷 기반 온라인 플랫폼과 관련된 서비스를 포함하고 있습니다. MegaEvolution에서는 각각의 서비스 제공을 자유롭게 이용할 수 있습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_11]: `2.11. MegaEvolution은 중재자나 조정자가 아니지만, 서비스가 설명된 대로 제공되지 않았거나 크리에이터가 전자적 수단이나 고객 지원을 통해 응답하지 않는 경우에는 제한적인 분쟁 해결 서비스를 제공합니다. 이러한 결정은 양측에게 구속력을 갖습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_12]: `2.12. 상기 내용을 제외하고, 서비스 사용자를 위한 서비스는 크리에이터를 알아보는 기회를 제공하는 것으로 제한되며, 크리에이터를 위한 서비스는 크리에이터가 자체적으로 프로필을 작성하고 다른 사용자가 연락할 수 있도록 장려하는 기회를 제공하는 것으로 제한됩니다. MegaEvolution은 사용자가 적합한 크리에이터를 찾을 수 있거나 크리에이터가 적합한 사용자를 찾을 수 있다는 것을 보장하지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_13]: `2.13. MegaEvolution은 크리에이터가 제공하는 콘텐츠나 크리에이터가 광고한 서비스의 요구 사항을 충족시킬 능력, 또는 크리에이터가 제공하는 어떠한 정보에 대해서도 책임을 지지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_14]: `2.14. MegaEvolution은 크리에이터가 제공하는 제안에 영향을 미치지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_15]: `2.15. MegaEvolution은 거짓 정보나 본 약관의 심각한 위반 사항이 있는 경우 사전 통지나 이유를 밝히지 않고 크리에이터의 프로필과 통계 데이터를 저장하거나 삭제할 권리를 보유합니다. 심각한 속임수, 문서 위조 또는 의도적인 오남용의 경우 MegaEvolution은 법적 조치를 포함한 적절한 조치를 취할 것입니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_16]: `2.16. MegaEvolution을 통해 제공되는 제3자 서비스는 명확하게 표시됩니다. MegaEvolution은 해당 서비스를 이용할 때 이에 대한 책임을 지지 않습니다.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_17]: `2.17. 플랫폼에 접근함으로써, 모든 이용 약관을 읽었으며 이해하고 수락하는 것에 동의합니다. 만약 모든 이용 약관에 동의하지 않는다면, 플랫폼의 사용이 명시적으로 금지되며 즉시 사용을 중지해야 합니다.`,

  // 3. My Balance and Refund Policy
  [TermsConditionsKeys.Mega_Balance.title]: '3. My Balance and Refund Policy',

  [TermsConditionsKeys.Mega_Balance
    ._3_1]: `3.1. 당사의 "나의 잔고(My Balance)" 기능을 통해 수익금을 인출하거나 환불금을 결제했던 수단으로 환불 받거나 구매에 사용할 수 있습니다. (일부 서비스 제외)`,
  [TermsConditionsKeys.Mega_Balance
    ._3_2]: `3.2. 플랫폼 내의 환불은 서비스의 종류 및 주문의 진행 정도에 따라 결제했던 수단으로 직접 환불되거나 나의 잔고(My Balance)로 환불될 수 있습니다.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_3]: `3.3. 나의 잔고(My Balance)로 환불된 금액은 결제 업체에 따라 일정 기간(업체에 따라 약 3~6개월) 이후에는 결제했던 수단으로 환불 받을 수 없지만 플랫폼에서 구매를 위해 계속 사용할 수 있습니다.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_4]: `3.4. 나의 잔고(My Balance)의 금액은 USD로 표시됩니다.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_5]: `3.5. 나의 잔고(My Balance)를 통한 환불 및 인출은 각 결제 업체를 통해 신속하게 진행되지만, 시스템 및 기타 사유로 인해 환불 및 인출이 지연될 수 있습니다.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_6]: `3.6. 수익금은 주문이 완료된 이후 서비스 종류에 따라 일정한 날짜가 지난 뒤에 나의 잔고(My Balance)를 통해 인출이 가능합니다.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_7]: `3.7. MegaEvolution 플랫폼에서 나의 잔고(My Balance)의 사용 및 인출에 적용되는 모든 소비세 및 기타 세금 및 요금에 대한 책임은 귀하에게 있습니다.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_8]: `3.8. 나의 잔고(My Balance)를 통해 환불 및 인출이 진행되는 경우 결제 업체 측에서 문제가 발생할 경우 이에 관한 분쟁은 해당 결제 업체와 직접 해결되어야 합니다.`,

  // 4. Prices and Payment
  [TermsConditionsKeys.Prices_Payment.title]: '4. 가격 및 지불',

  [TermsConditionsKeys.Prices_Payment
    ._4_1]: `4.1. 크리에이터가 제시한 각 서비스의 가격은 주문이 이루어진 시점에 적용됩니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_2]: `4.2. 체크아웃 중 구매자에게 표시된 가격은 최종가로서, 적용 가능한 법적 부가가치세가 포함됩니다. 추가적으로 다양한 서비스 수수료가 부과될 수 있으며, 서비스 수수료의 금액은 사용자의 구독 플랜 및 서비스의 가격에 따라 다를 수 있습니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_3]: `4.3. 적용되는 가격은 해당 서비스 설명에 표시됩니다. 서비스 사용자는 해당 가격을 지불할 의무가 있습니다. 주문에 따라 결제는 서비스나 제품을 주문할 때 또는 주문이 크리에이터에게 완전히 승인된 후에 완료될 수 있습니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_4]: `4.4. 가격과 결제 방법은 플랫폼에 게시되며, 이는 이용 약관에 포함됩니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_5]: `4.5. 결제는 MEGA Balance가 MegaEvolution 계정이나 크리에이터를 대신하여 수수료를 징수하는 제3자의 계정에 입금된 시점으로 간주됩니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_6]: `4.6. 서비스 사용자는 지불한 금액이 올바르며, 자신이 거주하는 국가에서 적용되는 세금을 준수하는 것에 대해 전적으로 책임이 있습니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_7]: `4.7. 서비스 사용자는 서비스를 받기 위해 필요한 제3자 서비스(통신 서비스, 인터넷 등)의 비용을 지불하는 것에 전적으로 책임이 있습니다.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_8]: `4.8. 지불 미이행 시, MegaEvolution은 기타 권리 행사와 무관하게 현재의 모든 주문을 중단할 수 있습니다.`,

  // 5. Creator Renumeration and Payout
  [TermsConditionsKeys.Creator_Renumeration.title]:
    '5. 크리에이터 보상 및 지급',

  [TermsConditionsKeys.Creator_Renumeration
    ._5_1]: `5.1. MegaEvolution에서 구매 가능한 서비스를 제공할 때, 크리에이터는 서비스의 가격을 선택해야 합니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_2]: `5.2. 서비스 사용자가 서비스를 구매할 경우, MegaEvolution은 서비스 사용자가 실제로 지불한 금액을 총액으로 계산합니다. 이 금액에서 거래 세금과 가격 및 구독 플랜에 따라 다른 서비스 및 처리 수수료, 콘텐츠와 관련하여 제3자에게 지급된 금액을 차감하여 순액을 계산합니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_3]: `5.3. MegaEvolution은 언제든지 2항에서 설명한 서비스 및 처리 수수료를 변경할 권리를 보유합니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_4]: `5.4. MegaEvolution은 통화 환전 수수료, 송금 수수료 또는 기타 처리 수수료에 대해서는 책임지지 않습니다. 수익 보고서에는 판매 가격과 변환된 수익 금액이 표시됩니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_5]: `5.5. MegaEvolution과 크리에이터는 서로 독립 계약자 관계를 형성하고 있으며, 크리에이터는 어떠한 목적으로도 MegaEvolution의 직원으로 간주되지 않습니다. 크리에이터는 MegaEvolution이 직원에게 제공하는 의료, 퇴직, 휴가, 유급 또는 무급 휴가, 기타 혜택을 수령하거나 참여할 자격이 없습니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_6]: `5.6. 크리에이터는 사회보장, 자영업, 소득세, 장애 보험, 직장 안전 보험, 직원에게 제공해야 하는 기타 법정 혜택 및 수수료와 면허(있는 경우) 등, 본 서비스를 제공하기 위해 필요한 모든 세금과 라이선스에 대한 전적인 책임을 집니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_7]: `5.7. 크리에이터는 수익을 인출하기 위해 MegaEvolution의 결제 서비스 제공업체 중 최소한 한 곳과 계정을 보유해야 합니다. 인출 가능한 모든 자금은 MegaEvolution의 결제 서비스 제공업체에 소유하고 있습니다. 인출 서비스를 포함한 모든 결제 서비스는 MegaEvolution의 결제 서비스 제공업체에 의해 제공됩니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_8]: `5.8. 크리에이터의 MegaEvolution 프로필은 MegaEvolution 인출 방법 중 하나의 계정과만 연결될 수 있습니다. 결제 서비스 제공업체의 인출 계정은 하나의 MegaEvolution 프로필과만 연결될 수 있습니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_9]: `5.9. 크리에이터는 주문이 완료로 표시된 뒤 일정한 날짜가 지낸 후에 수익을 인출할 수 있습니다. 수익은 청구 페이지에서 인출할 수 있도록 제공됩니다.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_10]: `5.10. 보안 문제로 인해, 사기 또는 불법 활동을 방지하기 위해 MegaEvolution은 일시적으로 크리에이터의 수익 인출 기능을 비활성화할 수 있습니다. 이는 보안 문제, 다른 사용자가 신고한 부적절한 행동 또는 하나의 인출 제공업체에 여러 MegaEvolution 계정을 연결하는 경우에 발생할 수 있습니다.`,

  // 6. User Representations
  [TermsConditionsKeys.User_Representations.title]: '6. 사용자 정책',

  [TermsConditionsKeys.User_Representations
    ._6_1]: `6.1. 플랫폼을 사용함으로써, 다음과 같은 사실과 보증을 제시합니다:`,
  [TermsConditionsKeys.User_Representations
    ._6_1_1]: `6.1.1. 제출하는 모든 등록 정보는 진실하고 정확하며 현재이며 완전해야 합니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_2]: `6.1.2. 이러한 정보의 정확성을 유지하고 필요한 경우 즉시 해당 등록 정보를 업데이트할 것입니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_3]: `6.1.3. 법적으로 능력이 있으며 이러한 약관을 준수하기로 동의합니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_4]: `6.1.4. 만 18세 이상인 경우입니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_5]: `6.1.5. 봇, 스크립트 또는 기타 방법을 통해 자동화된 또는 비인간적인 수단으로 플랫폼에 접근하지 않을 것입니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_6]: `6.1.6. 플랫폼을 불법적이거나 권한이 없는 목적으로 사용하지 않을 것입니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_7]: `6.1.7. 플랫폼의 사용은 관련 법률이나 규제를 위반하지 않을 것입니다.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_8]: `6.1.8. 만약 허위, 부정확, 현재가 아니거나 불완전한 정보를 제공한 경우, 우리는 귀하의 계정을 일시 중지하거나 해지하고 플랫폼의 모든 현재 및 미래 사용을 거절할 권리가 있습니다.`,

  // 7. No reliance on information and limitation of liability
  [TermsConditionsKeys.No_Reliance.title]:
    '7. 정보에 대한 의존 금지 및 책임 제한',

  [TermsConditionsKeys.No_Reliance
    ._7_1]: `7.1. 저희 플랫폼에 게시된 해설 및 기타 자료는 의존할 수 있는 조언으로 간주되지 않으며, 단지 안내 목적으로 제공됩니다.`,
  [TermsConditionsKeys.No_Reliance
    ._7_2]: `7.2. MegaEvolution은 플랫폼의 콘텐츠가 정확하고 완전하며 최신임을 보장하는 어떠한 표현, 보증, 또는 보장도 하지 않습니다.`,
  [TermsConditionsKeys.No_Reliance
    ._7_3]: `7.3. MegaEvolution은 플랫폼의 사용 또는 그와 관련된 사용 또는 사용 상실로 인해 발생하는 어떠한 손실 또는 손해에 대해서도 책임지지 않습니다. 이는 계약 또는 불법행위(과실 포함), 법적 의무 위반 또는 기타 방법에 따라 발생하는 손실, 간접 손해, 부수적 손해 또는 결과적 손해를 포함하며, 미리 예견된 경우에도 해당됩니다.`,
  [TermsConditionsKeys.No_Reliance
    ._7_4]: `7.4. 플랫폼의 사용 또는 해당 플랫폼에 연결된 어떠한 콘텐츠의 다운로드로 인해 당신의 기기 장비, 기기 프로그램, 데이터 또는 기타 자산에 바이러스, 분산 서비스 거부 공격 또는 기술적으로 해로운 자료가 감염될 경우 발생하는 어떠한 손실 또는 손해에 대해서도 책임지지 않습니다.`,
  [TermsConditionsKeys.No_Reliance
    ._7_5]: `7.5. 이는 우리의 과실로 인한 사망이나 개인상해에 대한 책임, 부정한 허위 표현에 대한 책임, 해당 법에 따라 제외하거나 제한할 수 없는 기타 책임에는 영향을 미치지 않습니다.`,

  // 8. Technical requirements and responsibility
  [TermsConditionsKeys.Technical_Requirements.title]:
    '8. 기술적 요구사항과 책임',

  [TermsConditionsKeys.Technical_Requirements
    ._8_1]: `8.1. 사용자는 해당 서비스에 대한 액세스 및 사용을 위한 기술적 요구사항을 충족하는 것에 대한 책임이 있습니다.`,
  [TermsConditionsKeys.Technical_Requirements
    ._8_2]: `8.2. 이는 특히 사용되는 하드웨어와 운영 체제 소프트웨어, 인터넷 연결, 방화벽 설정(있는 경우) 및 최신 브라우저 소프트웨어에 적용됩니다. 사용자는 필요한 조치를 스스로 취하고 인터넷 연결 비용을 부담하여 해당 서비스에 액세스할 수 있어야 합니다.`,

  // 9. Uploading content to our Platform
  [TermsConditionsKeys.Uploading_Content.title]: '9. 콘텐츠 업로드 정책',

  [TermsConditionsKeys.Uploading_Content
    ._9_1_l1]: `9.1. 당신은 변경할 수 없고 절대적으로, 또 무조건적으로 당신이 우리의 플랫폼에 업로드하는 어떠한 내용도 우리의`,
  [TermsConditionsKeys.Uploading_Content._9_1_l2]: `개인정보 처리방침`,
  [TermsConditionsKeys.Uploading_Content
    ._9_1_l3]: `및 적용되는 법률을 준수한다는 것을 보증합니다.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_2]: `9.2. 플랫폼에 업로드한 내용에 대한 모든 책임은 전적으로 당신에게 있습니다. 우리는 다음과 같은 사항에 대해 어떠한 책임도 지지 않으며, 제3자에 대해서도 책임지지 않습니다:`,
  [TermsConditionsKeys.Uploading_Content
    ._9_2_1]: `9.2.1. 당신이 업로드하거나 우리가 당신을 대신하여 업로드한 어떠한 내용이나 데이터의 내용 또는 정확성; 또는`,
  [TermsConditionsKeys.Uploading_Content
    ._9_2_2]: `9.2.2. 당신이 우리에게 제공한 모든 내용과 데이터(물리적 또는 디지털 형태로)의 손실. 당신은 그러한 내용과 데이터의 기록을 보관해야 합니다.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_3]: `9.3. 당신이 업로드한 내용은 서비스 제공, 본 계약의 의무 이행, 본 계약이나 우리 사이에 명시적으로 정해진 목적을 위해만 사용될 것입니다. 그 외에는 법률, 관할 법원, 정부 기관 또는 규제 기관에서 요구하는 경우를 제외하고는 당신이 업로드한 내용을 공개하거나 배포하지 않을 것입니다.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_4]: `9.4. 당신이 업로드한 내용은 데이터 분석이나 인공지능 또는 기계 학습 구현을 위한 목적으로 사용될 수 있습니다. 그러한 내용은 익명화되고, 서비스 개선 및 플랫폼 사용자에 대한 응답을 개선하기 위한 목적으로만 사용될 것입니다.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_5]: `9.5. 당신이 우리의 플랫폼에 게시하거나 업로드한 어떠한 내용이 해당 법률에 따라 그들의 권리를 침해하는 것으로 주장하는 제3자에게 당신의 신원을 공개할 권리를 갖습니다.`,

  // 10. Contribution License
  [TermsConditionsKeys.Contribution_License.title]: '10. 기여 라이선스',

  [TermsConditionsKeys.Contribution_License
    ._10_1]: `10.1. 플랫폼의 어떤 부분에 기여물(Contributions)을 게시함으로써, 당신은 자동적으로 우리에게 제한 없이, 무제한, 철회 불가능한, 영구적인, 비독점적이며 양도 가능하며, 무료로, 완전히 지급된, 전 세계적으로 호스팅, 사용, 복사, 복제, 공개, 게시, 방송, 이름 변경, 보관, 캐시, 공공연하게 수행, 공개적으로 표시, 형식을 변경, 번역, 전송, 인용(전체 또는 일부)하고, 상기 기여물(Contributions) (이미지와 음성을 포함한)을 어떠한 상업적, 광고적 또는 기타 목적으로도 사용하고, 파생 작품으로 준비하거나 다른 작품에 포함시키며, 상기의 권리에 대한 하위 라이선스를 부여하고 승인할 수 있는 권리를 무제한으로, 무제한으로, 철회 불가능하게, 영구적으로, 비독점적으로, 양도 가능하게 우리에게 부여하고 소유권을 주장하지 않는 한 제3자에 의해 도덕적 권리가 주장되지 않는 것을 보증합니다. 사용 및 배포는 어떤 매체 형식과 매체 채널을 통해 이루어질 수 있습니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_2]: `10.2. 이 라이선스는 현재 알려진 또는 앞으로 개발되는 모든 형태, 매체, 기술에 적용되며, 당신의 이름, 회사 이름, 그리고 브랜드 이름(해당되는 경우) 및 제공하는 상표, 서비스 표시, 상호명, 로고, 개인 및 상업 이미지를 포함합니다. 당신은 기여물에 대한 도덕적 권리를 모두 포기하고, 도덕적 권리가 기여물에 대해 주장되지 않았음을 보증합니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_3]: `10.3. 당사는 당신의 기여물(Contributions)에 대한 소유권을 주장하지 않습니다. 당신은 당신의 기여물과 관련된 모든 지적 재산권 또는 기타 소유권에 대한 완전한 소유권을 보유합니다. 플랫폼의 어느 영역에서든 당신이 제공하는 기여물의 어떠한 진술이나 표현에 대해 당사는 책임이 없습니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_4]: `10.4. 당신은 플랫폼에 대한 기여물에 대해서만 책임을 부담하며, 당사에 대한 어떠한 책임도 면제하고 기여물과 관련하여 어떠한 법적 조치도 취하지 않을 것에 명시적으로 동의합니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_5]: `10.5. 당사는 단독적인 재량에 따라`,
  [TermsConditionsKeys.Contribution_License
    ._10_6]: `10.6. 기여물을 편집, 삭제 또는 다른 방식으로 변경할 수 있습니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_7]: `10.7. 기여물을 더 적절한 위치에 재분류할 수 있습니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_8]: `10.8. 언제든지 이유에 상관없이 기여물을 사전 스크리닝하거나 삭제할 수 있으며 사전 통지 없이 삭제할 수 있습니다.`,
  [TermsConditionsKeys.Contribution_License
    ._10_9]: `10.9. 당사는 기여물을 모니터링할 의무가 없습니다.`,

  // 11. Restrictions
  [TermsConditionsKeys.Restrictions.title]: '11. 제한사항',

  [TermsConditionsKeys.Restrictions
    ._11_1]: `11.1. 본 계약에 명시적으로 규정되거나 관련 법률에 허용된 경우를 제외하고 다음 사항을 준수해야 합니다:`,
  [TermsConditionsKeys.Restrictions
    ._11_1_1]: `11.1.1. 상업적 또는 기타 목적으로 어떠한 서비스, 당사 플랫폼 또는 그 내용 전체 또는 일부를 재생산, 복제, 수정, 적응, 번역, 게시, 표시, 전달, 판매, 이용하거나 활용하지 않습니다.`,
  [TermsConditionsKeys.Restrictions
    ._11_1_2]: `11.1.2. 당사 플랫폼의 전체 또는 일부 소스 코드를 해체, 디컴파일, 리버스 엔지니어링하거나 이를 기반으로 한 파생작품을 작성하는 등의 행위나 서비스, 당사 플랫폼 또는 그 내용에 대한 재생산, 표시 또는 기타 접근을 제공하는 행위(예: 프레임, 미러링, 링크, 스파이더링, 스크래핑 또는 기술적인 방법 등)를 시도하지 않습니다.`,
  [TermsConditionsKeys.Restrictions
    ._11_1_3]: `11.1.3. 당사의 사전 서면 동의 없이 당사 플랫폼 전체 또는 일부(프로그램 목록, 개체 및 소스 프로그램 목록, 개체 코드 및 소스 코드를 포함)를 어떠한 형태로든 다른 사람에게 제공하거나 이용할 수 없습니다.`,
  [TermsConditionsKeys.Restrictions
    ._11_1_4]: `11.1.4. 당사 플랫폼의 전체 및 부분 복사본을 제작할 때 모든 매체에 당사의 저작권 표시를 포함해야 합니다.`,
  [TermsConditionsKeys.Restrictions
    ._11_1_5]: `11.1.5. 모든 적용 가능한 기술 제어 또는 수출 규정을 준수해야 합니다.`,
  [TermsConditionsKeys.Restrictions
    ._11_1_6]: `11.1.6. 해킹, 사이버 공격(예: 서비스 거부 공격을 포함한), 개입 또는 재프로그래밍 등을 통해 서비스, 당사 플랫폼 또는 서버의 정상적인 작동을 방해하거나 비활성화하거나 손상시키는 행위를 하지 않아야 합니다.`,

  // 12. Intellectual Property Rights
  [TermsConditionsKeys.Intellectual_Property.title]: '12. 지적 재산권',

  [TermsConditionsKeys.Intellectual_Property
    ._12_1]: `12.1. 당사 플랫폼에 대한 전 세계적인 지적 재산권이 당사에 속하고, 플랫폼의 권리가 당신에게 라이선스(판매가 아닌)되었으며, 본 약관에 따라 플랫폼을 사용할 권리 이외에는 플랫폼에 대한 어떠한 권리도 없음을 인정합니다.`,
  [TermsConditionsKeys.Intellectual_Property
    ._12_2]: `12.2. 당신이 당사 플랫폼에 업로드한 콘텐츠의 지적 재산권은 당신이나 해당 소유자에게 계속 속해 있음을 인정합니다. 당신은 당사에 대해 로열티 없는 비독점적 라이선스를 부여하고, 서비스 수행, 홍보 목적, 내부 관리 목적 및 본 약관에 명시된 기타 목적(플랫폼 사용자에 대한 응답 개선 등)을 위해 해당 지적 재산권을 사용, 복제, 게시 및 표시할 수 있는 권리를 부여하는 데 동의합니다.`,
  [TermsConditionsKeys.Intellectual_Property
    ._12_3]: `12.3. 소스 코드 형태의 당사 플랫폼에 대한 액세스 권한이 없음을 인정합니다.`,

  // 13. Acceptable Use Policy
  [TermsConditionsKeys.Acceptable_Use_Policy.title]:
    '13. 수용 가능한 이용 정책',

  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_1]: `13.1. 당신은 당사 플랫폼을 법적인 목적으로만 사용할 수 있습니다.`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2]: `13.2. 다음과 같은 방법으로 당사 플랫폼을 사용할 수 없습니다:`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_1]: `13.2.1. 해당하는 국내외 법령을 위반하는 방식으로 사용하는 경우;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_2]: `13.2.2. 불법적이거나 사기적이며, 불법적이거나 사기적인 목적 또는 효과를 가지는 경우;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_3]: `13.2.3. 당사의 콘텐츠 기준을 준수하지 않는 재료를 전송, 수신, 업로드, 다운로드, 사용 또는 재사용하는 경우 (최신 약관에 명시된 내용을 포함하여);`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_4]: `13.2.4. 바이러스, 트로이 목마, 웜, 타임밤, 키로깅 로거, 스파이웨어, 애드웨어 또는 기타 유해한 프로그램 또는 기기의 작동을 해를 끼치기 위해 설계된 유사한 장치 코드를 포함한 데이터를 알고 있으면서 전송하거나 업로드하는 경우, 또는 그와 유사한 방법을 사용하는 경우.`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_5]: `13.2.5. 또한, 다음에 동의합니다:`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_6]: `13.2.6. 당사 약관의 규정을 위반하여 당사 플랫폼의 일부를 복제, 중복, 복사 또는 재판매하지 않는다;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_7]: `13.2.7. 권한 없이 다음과 같은 당사 플랫폼의 일부를 접근하거나`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_8]: `13.2.8. 간섭하거나 손상시키지 않거나 방해하지 않는다:`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_9]: `13.2.9. 당사 플랫폼이 저장된 장비 또는 네트워크;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_10]: `13.2.10. 당사 플랫폼 제공에 사용되는 소프트웨어; 또는`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_11]: `13.2.11. 제3자가 소유하거나 사용하는 장비, 네트워크 또는 소프트웨어.`,

  // Suspension
  [TermsConditionsKeys.Suspension.title]: '14. 정지사항',

  [TermsConditionsKeys.Suspension
    ._14_1]: `14.1. 우리는 재량에 따라 당사 플랫폼 사용을 통해 우리의 허용 가능 사용 정책에 위반되었는지 여부를 결정할 것입니다.`,
  [TermsConditionsKeys.Suspension
    ._14_2]: `14.2. 이 정책에 위반된 경우, 우리는 적절하다고 판단되는 조치를 취할 수 있습니다.`,
  [TermsConditionsKeys.Suspension
    ._14_3]: `14.3. 우리의 허용 가능 사용 정책을 준수하지 않는 것은 당사 플랫폼 사용을 허용하는 약관에 대한 중대한 위반이며, 우리는 다음 중 하나 이상의 조치를 취할 수 있습니다:`,
  [TermsConditionsKeys.Suspension
    ._14_3_1]: `14.3.1. 즉시 당사 플랫폼 사용 권한의 일시적 또는 영구적인 취소;`,
  [TermsConditionsKeys.Suspension
    ._14_3_2]: `14.3.2. 즉시 기여물의 일시적 또는 영구적인 제거;`,
  [TermsConditionsKeys.Suspension._14_3_3]: `14.3.3. 경고 발송.`,
  [TermsConditionsKeys.Suspension
    ._14_3_4]: `14.3.4. 위반으로 인해 발생하는 모든 비용(합리적인 행정 및 법적 비용을 포함하되 이에 국한되지 않음)에 대한 배상을 요구하기 위해 당신에 대해 법적 조치;`,
  [TermsConditionsKeys.Suspension
    ._14_3_5]: `14.3.5. 당신에 대한 추가적인 법적 조치; 및/또는`,
  [TermsConditionsKeys.Suspension
    ._14_3_6]: `14.3.6. 필요한 것으로 판단되는 법 집행 기관에 대한 해당 정보의 공개.`,
  [TermsConditionsKeys.Suspension
    ._14_4]: `14.4. 허용 가능 사용 정책 위반에 대한 조치에 대한 책임은 제외됩니다. 이 정책에 설명된 조치는 제한되지 않으며, 합리적으로 적절하다고 판단되는 다른 조치를 취할 수 있습니다.`,

  // 15. Submissions
  [TermsConditionsKeys.Submissions.title]: '15. 제출 정책',

  [TermsConditionsKeys.Submissions
    ._15_1]: `15.1. 당신은 당신이 우리에게 제공하는 플랫폼에 관한 질문, 의견, 제안, 아이디어, 피드백 또는 기타 정보("제출물")가 비밀이 아니며, 우리의 독점 재산이 될 것임을 인정하고 동의합니다. 우리는 제출물에 대한 배타적 권리, 포함하여 지적 재산권을 보유하며, 인정이나 보상 없이 이러한 제출물을 어떤 법적 목적으로든 자유롭게 사용하고 배포할 권리를 가지게 됩니다.`,
  [TermsConditionsKeys.Submissions
    ._15_2]: `15.2. 여기에 따라 당신은 해당 제출물에 대한 모든 도덕적 권리를 포기하며, 해당 제출물이 당신의 원작이거나 해당 제출물을 제출할 권리가 있다는 것을 보증합니다. 당신은 당신의 제출물에 대한 어떠한 소유권 침해 또는 불법 사용에 대해 우리에 대한 어떠한 구제 조치도 할 수 없음에 동의합니다.`,

  // 16. Modifications And Interruptions
  [TermsConditionsKeys.Modifications.title]: '16. 수정 및 방해',

  [TermsConditionsKeys.Modifications
    ._16_1]: `16.1. 우리는 단독 재량으로 언제든지 미리 고지 없이 플랫폼의 내용을 변경, 수정 또는 삭제할 권리를 보유합니다. 그러나 우리는 플랫폼의 모든 정보를 업데이트할 의무가 없습니다. 우리는 또한 사전 고지 없이 플랫폼 전체 또는 일부를 수정하거나 중단할 권리를 보유합니다.`,
  [TermsConditionsKeys.Modifications
    ._16_2]: `16.2. 플랫폼의 변경, 가격 변경, 일시 중단 또는 중단으로 인해 당신이나 제3자에게 어떠한 책임도 지지 않을 것입니다.`,
  [TermsConditionsKeys.Modifications
    ._16_3]: `16.3. 우리는 플랫폼이 언제나 사용 가능할 것을 보장할 수 없습니다.`,
  [TermsConditionsKeys.Modifications
    ._16_4]: `16.4. 우리는 하드웨어, 소프트웨어 또는 기타 문제가 발생하거나 유지 보수 작업이 필요할 수 있으며, 이로 인해 플랫폼의 중단, 지연 또는 오류가 발생할 수 있습니다.`,
  [TermsConditionsKeys.Modifications
    ._16_5]: `16.5. 우리는 언제든지 사전 고지 없이 플랫폼을 변경, 수정, 업데이트, 일시 중단, 중단 또는 기타 방식으로 수정할 권리를 보유합니다. 당신이 플랫폼의 다운타임이나 중단으로 인해 플랫폼에 접속하거나 사용할 수 없어 발생하는 어떠한 손실, 피해 또는 불편에 대해 우리는 어떠한 책임도 지지 않는 데 동의합니다.`,
  [TermsConditionsKeys.Modifications
    ._16_6]: `16.6. 이 약관에 따라 우리에게 플랫폼을 유지 및 지원하거나 이와 관련하여 어떠한 수정, 업데이트 또는 릴리스를 제공할 의무가 있다고 해석되지 않습니다.`,

  // 17. Availability of the Platform
  [TermsConditionsKeys.Availability.title]: '17. 플랫폼 사용',

  [TermsConditionsKeys.Availability
    ._17_1]: `17.1. 우리의 플랫폼은 "있는 그대로" 및 "사용 가능한 상태로" 제공됩니다. 우리는 플랫폼이 결함이나 오류가 없을 것이라는 보증을 제공하지 않습니다. 법률이 허용하는 최대 범위 내에서, 우리는 명시적 또는 묵시적으로 특정 목적에의 적합성, 정보의 정확성, 호환성 및 만족스러운 품질에 대한 어떠한 보증도 제공하지 않습니다.`,
  [TermsConditionsKeys.Availability
    ._17_2]: `17.2. MegaEvolution은 ISP 장비 고장, 호스트 장비 고장, 통신 네트워크 고장, 전원 고장, 자연 재해, 전쟁 행위 또는 법적 제한 및 검열 등 외부 원인으로 인해 플랫폼이 중단되거나 사용할 수 없는 경우에 대해 어떠한 책임도 지지 않습니다.`,

  // 18. Corrections
  [TermsConditionsKeys.Corrections.title]: '18. 수정 사항',

  [TermsConditionsKeys.Corrections
    ._18_1]: `플랫폼에는 기술적 오류, 부정확성 또는 누락이 포함된 정보가 있을 수 있습니다. 이러한 오류, 부정확성 또는 누락이 플랫폼, 설명, 가격, 가용성 및 기타 다양한 정보와 관련될 수 있습니다. 우리는 언제든지 사전 공지 없이 플랫폼의 정보를 수정하거나 업데이트하며, 오류, 부정확성 또는 누락을 정정하는 권리를 보유합니다.`,

  // 19. Privacy
  [TermsConditionsKeys.Privacy.title]: '19. 보안 정책',

  [TermsConditionsKeys.Privacy
    ._19_1_l1]: `19.1. 적용 가능한 데이터 보호 법령에 따라, MegaEvolution은 당신이 제공한 개인 데이터를 MegaEvolution 플랫폼에서 제공되는`,
  [TermsConditionsKeys.Privacy._19_1_l2]: `개인정보 처리 방침`,
  [TermsConditionsKeys.Privacy
    ._19_1_l3]: `또는 MegaEvolution으로부터의 요청에 따라 처리할 것입니다.`,
  [TermsConditionsKeys.Privacy
    ._19_2]: `19.2. 만약 당신이 MegaEvolution에게 제3자와 관련된 개인 데이터를 제공한 경우:`,
  [TermsConditionsKeys.Privacy
    ._19_2_1]: `19.2.1. 당신은 해당 개인 데이터를 MegaEvolution에 합법적으로 전송하기 위해 필요한 적절한 동의와 고지를 갖추고 있다는 것에 동의합니다.`,
  [TermsConditionsKeys.Privacy
    ._19_2_2]: `19.2.2. 그리고 당신은 해당 제3자에게 MegaEvolution 플랫폼에서 제공되는 개인정보 공지사항을 알리거나 해당 제3자에게 사본을 제공한 것을 알렸음을 동의합니다.`,
  [TermsConditionsKeys.Privacy
    ._19_3]: `19.3. 이러한 요구 사항을 준수하지 않아 발생하는 모든 책임, 처분, 벌금, 수상 또는 비용에 대해 MegaEvolution을 보호하도록 동의합니다.`,

  // 20. Term and Termination
  [TermsConditionsKeys.Term_Termination.title]: '20. 기간 및 해지',

  [TermsConditionsKeys.Term_Termination
    ._20_1]: `20.1. 본 이용약관은 당신이 플랫폼을 사용하는 동안에 완전히 유효하며 효력을 유지합니다. 본 이용약관의 다른 조항을 제한하지 않고, 우리는 재량에 따라 어떤 이유든지 그 이유의 유무에 관계없이 플랫폼에 대한 접근과 이용(특정 IP 주소 차단 포함)을 거부할 수 있으며, 이에 대해 어떠한 통지나 책임도 지지 않습니다. 이용약관에 포함된 어떠한 표현, 보증, 계약의 위반 또는 적용 가능한 법률 또는 규정의 위반으로 인해라도, 언제든지 당신의 플랫폼 이용 또는 참여를 종료하거나 당신의 계정과 게시한 모든 콘텐츠 또는 정보를 임의로 삭제할 수 있습니다. 이는 우리의 재량에 따라 경고 없이 이루어질 수 있습니다.`,
  [TermsConditionsKeys.Term_Termination
    ._20_2]: `20.2. 우리가 어떠한 이유로든 당신의 계정을 종료 또는 정지시키면, 당신은 당신의 이름, 가짜 또는 대여한 이름, 또는 제3자의 이름으로 새로운 계정을 등록하고 생성하는 것이 금지됩니다. 심지어 제3자를 대신하여 행동하고 있을 수 있더라도 그렇습니다.`,
  [TermsConditionsKeys.Term_Termination
    ._20_3]: `20.3. 계정을 종료하거나 정지시키는 것 외에도, 우리는 민사, 형사 및 가처분적 구제 조치를 포함한 적절한 법적 조치를 취할 권리를 보유합니다.`,

  // 21. Limitation of Liability

  [TermsConditionsKeys.Limitation_Liability.title]: '21. 책임 제한',

  [TermsConditionsKeys.Limitation_Liability
    ._21_1]: `21.1. 우리는 우리의 플랫폼에 업로드된 어떠한 정보나 관련 콘텐츠의 완전성, 정확성 또는 정확성에 대해 책임을 지지 않습니다. 당신은 명시적으로 서비스와 우리의 플랫폼, 어떠한 조언에도 의존하는 것이 오로지 당신의 독립적인 책임에 따른다는 것에 동의합니다.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2]: `21.2. 당신은 재판매 목적으로 서비스, 우리의 플랫폼 및 관련 콘텐츠를 사용하지 않기로 동의하며, 우리는 이용약관에 따라 발생하는 어떠한 책임도 지지 않습니다(계약, 불법행위(과실 포함), 법적 의무 위반 또는 기타 사항에 따른 것으로 제한되지 않음) :`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_1]: `21.2.1. 이익, 매출, 사업 또는 수익의 손실;`,
  [TermsConditionsKeys.Limitation_Liability._21_2_2]: `21.2.2. 사업 중단;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_3]: `21.2.3. 예상된 절감의 손실;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_4]: `21.2.4. 데이터 또는 정보의 손실 또는 훼손;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_5]: `21.2.5. 사업 기회, 선의 또는 평판의 손실; 또는`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_6]: `21.2.6. 기타 간접적인 또는 결과적인 손실 또는 피해.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_3]: `21.3. 이 약관에는 다음과 같은 경우에 대한 책임을 제한하거나 배제하지 않습니다:`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_3_1]: `21.3.1. 우리의 과실로 인한 사망이나 개인상해;`,
  [TermsConditionsKeys.Limitation_Liability._21_3_2]: `21.3.2. 사기; 및/또는`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_3_3]: `21.3.3. 적용법에 따라 책임을 제한하거나 배제할 수 없는 다른 사안.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_4]: `21.4. 우리의 플랫폼은 기록 보관 기능을 제공하는 것을 목적으로 하지 않으며, 데이터나 콘텐츠의 손실에 대해 책임지지 않습니다.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_5]: `21.5. 이 약관은 서비스와 우리의 플랫폼의 공급에 대한 우리의 의무와 책임의 전부를 명시하고 있습니다. 본 약관에 명시된 것을 제외하고는, 우리에게 구속력이 있는 조건, 보증, 표현 또는 기타 약정이나 묵시적인 조건, 보증, 표현, 또는 기타 조건은 법률이 허용하는 최대한도로 배제됩니다.`,

  // 22. Other Important Terms
  [TermsConditionsKeys.Other_Important_Terms.title]:
    '22. 다른 중요한 이용 약관',

  [TermsConditionsKeys.Other_Important_Terms
    ._22_1]: `22.1. 우리는 본 약관에 따른 권리와 의무를 다른 조직에 양도할 수 있지만, 이는 본 약관에 따른 귀하의 권리나 의무에 영향을 미치지 않습니다.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_2]: `22.2. 우리의 서면 동의가 있는 경우에만 귀하의 권리나 의무를 다른 사람에게 양도할 수 있습니다.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_3]: `22.3. 이 약관을 통해 합작, 제휴, 대리 또는 고용 관계는 발생하지 않았습니다.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_4]: `22.4. 이 약관과 명시적으로 참조된 모든 문서는 그 주제에 관한 저희 사이의 전체 계약이며, 이전의 모든 계약, 약속, 보증, 표현, 확언, 보증, 표현 및 이해를 대체하고 소멸시킵니다. 이 약관이나 명시적으로 참조된 문서에 기재되지 않은 진술, 표현, 보증 또는 보증에 대한 구제조치가 없다는 데 동의합니다. 또한, 이 약관이나 명시적으로 참조된 문서에 기재된 진술에 기초한 부당하거나 과실로 인한 잘못된 표현이나 오진에 대한 청구권이 없다는 데 동의합니다.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_5]: `22.5. 우리가 귀하가 본 약관에 따른 의무를 이행하지 않도록 요구하지 않거나 귀하에 대한 권리를 시행하지 않거나 그러한 시행을 지연한 경우에도, 이는 우리가 귀하에 대한 권리를 포기한 것을 의미하지 않으며 귀하가 해당 의무를 준수하지 않아야 함을 의미하지 않습니다. 우리가 귀하의 채무 불이행을 사전에 면제한다면, 이는 서면으로만 그러한 면제를 한 것이며, 이로 인해 우리가 귀하의 이후의 채무 불이행을 자동적으로 면제하는 것을 의미하지 않습니다.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_6]: `22.6. 본 약관의 각 조건은 별개로 작용합니다. 어떤 법원이나 관할 기관이 그 중 어느 것이라도 불법이거나 강제가 불가능하다고 판단하더라도 나머지 조건은 완전하게 유효한 상태로 유지됩니다.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_7]: `22.7. 본 약관과 그 주제 및 구성, 그 외 이와 관련된 분쟁 또는 클레임은 대한민국 법률에 의해 규율됩니다. 본 약관과 관련된 이러한 분쟁 또는 클레임이 발생한 경우, 귀하는 우리와 선량한 논의에 참여하여 그러한 분쟁 또는 클레임을 해결하기로 동의합니다. 그러한 분쟁 또는 클레임이 60일 이내에 해결되지 않을 경우, 우리는 서로 대한민국의 전속 관할권을 무효화할 수 없음으로 간주하여 대한민국의 법원 전속 관할권에 절대적으로 따를 것에 동의합니다.`,
};
