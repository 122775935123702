import withAuthHOC from 'components/components/auth/withAuthHOC';
import { useTranslation } from 'react-i18next';
import ClientVerificationModal from '../PricePage/PriceSection/clientVerification/ClientVerificationModal';
import { useToast } from 'components/Basics/Toast';
import { useCallback } from 'react';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';

const CompanyVerificationModal = ({ flowManager }) => {
  const { closeModal } = flowManager;
  const { t } = useTranslation();
  const toast = useToast();

  const successfulSubmission = useCallback(() => {
    toast.success(t(PricingKeys.Modal.submission.toast));
  }, [t, toast]);

  return (
    <ClientVerificationModal
      isOpen={true}
      onClose={closeModal}
      setIsFormSubmittedPopupVisible={successfulSubmission}
    />
  );
};

export default withAuthHOC(CompanyVerificationModal);
